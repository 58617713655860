import {NEVER, Observable} from "rxjs";
import {PasswordChange} from "./model/password-change";
import {Injectable} from "@angular/core";
import {AuthenticationService} from "../config/authentication-service";
import {PasswordReset} from "./model/password-reset";

@Injectable()
export class AuthenticationServiceImpl implements AuthenticationService{
  changePasswordRequest(passwordChange: PasswordChange): Observable<void> {
    console.error('please provide your own AuthenticationService implementing changePasswordRequest', passwordChange);
    return NEVER;
  }
  resetPasswordRequest(emailAddress: string): Observable<void> {
    console.error('please provide your own AuthenticationService implementing resetPasswordRequest', emailAddress);
    return NEVER;
  }

  resetPassword(passwordReset: PasswordReset): Observable<void> {
    console.error('please provide your own AuthenticationService implementing resetPassword', passwordReset);
    return NEVER;
  }
  setPassword(passwordSet: PasswordReset): Observable<void> {
    console.error('please provide your own AuthenticationService implementing setPassword', passwordSet);
    return NEVER;
  }
}
