import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router, RouterLink} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {
  ButtonComponent,
  FormCardComponent,
  FormGridComponent,
  LogoComponent, MdefRequestPasswordResetFormComponent,
  RequestPasswordResetFormComponent,
  TextInputComponent,
} from 'component-library';
import {AuthenticationService} from "../../config/authentication-service";
import {AuthenticationConfig} from "../../config/authentication-config";

@Component({
  selector: 'tngh-request-reset-password-page',
  templateUrl: './request-password-reset-page.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    RouterLink,
    LogoComponent,
    FormCardComponent,
    FormGridComponent,
    TextInputComponent,
    RequestPasswordResetFormComponent,
    MdefRequestPasswordResetFormComponent]
})
export class RequestPasswordResetPageComponent {
  theme: 'tngh' | 'mdef';

  constructor(private authenticationService: AuthenticationService, private router: Router, private config: AuthenticationConfig) {
    this.theme = config.theme ?? 'tngh';
  }

  onSubmit(emailAddress: string) {
    this.authenticationService.resetPasswordRequest(emailAddress).subscribe();
  }

  onClickBackButton() {
    this.router.navigateByUrl('/');
  }
}
