import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ButtonComponent,
  confirmPasswordValidator,
  FormCardComponent,
  FormGridComponent,
  LogoComponent,
  TextInputComponent
} from 'component-library';
import { ActivatedRoute } from '@angular/router';
import {AuthenticationService} from "../../config/authentication-service";

@Component({
  selector: 'tngh-reset-password-page',
  templateUrl: './set-password-page.component.html',
  styleUrls: ['../login/login.component.scss', '../../../../component-library/src/lib/tablet/form-card/form-card-form-wrapper.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ButtonComponent, FormGridComponent, FormCardComponent, LogoComponent, TextInputComponent]
})
export class SetPasswordPageComponent {
  formGroup: FormGroup;
  resetCode: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      this.resetCode = params['resetCode'];
    });

    this.formGroup = fb.group(
      {
        newPassword: [undefined, [Validators.required, Validators.minLength(12)]],
        confirmPassword: [undefined, [Validators.required, Validators.minLength(12)]]
      },
      { validators: confirmPasswordValidator }
    );
  }

  onSubmit() {
    if (this.formGroup.valid && this.resetCode) {
      const resetPassword = {
        resetCode: this.resetCode,
        confirmPassword: this.formGroup.value.confirmPassword,
        newPassword: this.formGroup.value.newPassword
      };
      this.authenticationService.setPassword(resetPassword).subscribe();
    }
  }
}
