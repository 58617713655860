import {Component, EventEmitter, Input, Output} from '@angular/core';

import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgIf} from "@angular/common";
import {User} from "shared";
import {PinInputComponent, TextInputComponent} from "../../form-inputs";
import {AlertComponent} from "../../base/alert/alert.component";
import {ButtonComponent} from "../../base/button/button.component";
import {FormGridComponent, FormSectionComponent} from "../../layouts";
import {LogoComponent} from "../../base/logo/logo.component";
import {FormCardComponent} from "../../tablet";

@Component({
  selector: 'lib-otp-form',
  standalone: true,
  imports: [
    AlertComponent,
    ButtonComponent,
    FormGridComponent,
    FormsModule,
    LogoComponent,
    NgIf,
    ReactiveFormsModule,
    TextInputComponent,
    PinInputComponent,
    PinInputComponent,
    FormCardComponent,
    FormSectionComponent
  ],
  templateUrl: './otp-form.component.html',
  styleUrl: './otp-form.component.scss'
})
export class OtpFormComponent {
  @Input() loginError = false;
  @Output() sendOtpRequest = new EventEmitter<string>();
  @Output() loginRequest = new EventEmitter<User>();
  @Output() signup = new EventEmitter<void>();

  loginForm: FormGroup;
  showFormErrors = false;
  emailSent = false;

  constructor(fb: FormBuilder) {
    this.loginForm = fb.group({
      emailAddress: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  sendOtp() {
    this.sendOtpRequest.emit(this.loginForm.get('emailAddress')?.value ?? '');
    this.emailSent = true;
  }

  submit() {
    if (this.loginForm.invalid) {
      this.showFormErrors = true;
    } else {
      this.loginRequest.emit(this.loginForm.value);
    }
  }

  signupClick() {
    this.signup.emit();
  }
}
