import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {first, Observable, retry, throwError, timer} from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from 'shared';
import { AuthenticationConfig } from "../../../config/authentication-config";

@Injectable({ providedIn: 'root' })
export class AccessTokenApiInterceptor implements HttpInterceptor {
  private IGNORE_URLS = ['assets/i18n', 'password/reset'];
  constructor(private authService: AuthService, private authenticationConfig: AuthenticationConfig) {
    this.IGNORE_URLS = [
      ...this.IGNORE_URLS,
      this.authenticationConfig.keycloakUrl
    ]
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.IGNORE_URLS.some((ignoreUrl) => request.url.includes(ignoreUrl))) {
      return next.handle(request);
    }

    return this.authService.getToken().pipe(
      first(),
      mergeMap((accessToken) => next.handle(this.injectToken(request, accessToken)).pipe(retry({count: 10, delay: (err, retryCount) => {
          switch (err.status) {
            case 401:
            case 403:
            case 404:
              return throwError(() => err);
            default:
              return timer(1000 * (retryCount*retryCount));
          }
        }})))
    );
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public injectToken(request: HttpRequest<any>, jwt: string) {
    return request.clone({ setHeaders: { Authorization: `Bearer ${jwt}` } });
  }
}
