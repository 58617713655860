import {Injectable} from '@angular/core';
import {Observable, tap} from "rxjs";
import {SharedModuleConfig} from "../../../../config/shared-module-config";
import {OtpResponse} from "../modal/otp-response";
import {AuthService} from "./auth.service";
import {OtpUser, User} from "../../../../model";
import {headers} from "../modal/consts";
import {Token} from "../modal/token";

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  private sessionCode: string = '';
  private userName: string = '';

  constructor(private authService: AuthService, private sharedModuleConfig:  SharedModuleConfig) {

  }

  signup(newUser: OtpUser): Observable<OtpUser> {
    return this.authService.http.post<OtpUser>(`${this.sharedModuleConfig.serviceUrl ?? 'please define your service URL in the SharedModuleConfig (environment)'}/users/signup`, newUser);
  }

  sendOtpRequest(emailAddress: string): Observable<OtpResponse> {
    const body = this.createOtpBody(emailAddress);
    this.userName = emailAddress;

    return this.authService.http.post<OtpResponse>(`${this.sharedModuleConfig.keycloakUrl}/realms/users/protocol/openid-connect/token`, body.toString(), {headers}).pipe(
      tap(otp => this.sessionCode = otp.session_code)
    );
  }

  loginRequest(loginUser: User) {
    const body = this.createOtpBody(this.userName);
    body.set('session_code', this.sessionCode);
    body.set('code', loginUser.password);

    return this.authService.http.post<Token>(`${this.sharedModuleConfig.keycloakUrl}/realms/users/protocol/openid-connect/token`, body.toString(), {headers}).pipe(
      tap(this.authService.processToken.bind(this.authService))
    );
  }

  private createOtpBody(emailAddress: string) {
    const body = new URLSearchParams();
    body.set('client_id', this.sharedModuleConfig.clientId);
    body.set('client_secret', this.sharedModuleConfig.clientSecret);
    body.set('username', emailAddress);
    body.set('grant_type', 'password');
    return body;
  }
}
