import { AbstractControl, ControlValueAccessor, NgControl, UntypedFormControl, ValidationErrors, Validator } from '@angular/forms';
import { AfterViewInit, Component, OnInit, Optional, Self } from '@angular/core';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  template: ''
})
export class CvaBaseComponent implements ControlValueAccessor, OnInit, AfterViewInit, Validator {
  formControl: AbstractControl = new UntypedFormControl();
  errors: ValidationErrors | undefined | null;
  selfValidate = false;

  constructor(@Optional() @Self() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    } else {
      console.error(
        'Please provide a formControl or other NgControl. This message can only be ignored for storybook or unit testing. Subclass calling is:',
        this
      );
    }
  }

  ngAfterViewInit() {
    if (this.selfValidate) {
      setTimeout(() => {
        this.ngControl.control?.setValidators(this.validate.bind(this));
        this.ngControl.control?.updateValueAndValidity();
      });
    }
  }

  ngOnInit() {
    if (this.control) {
      this.errors = this.control?.errors;
      this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
        this.errors = this.control?.errors;
      });
    }
  }

  get control(): UntypedFormControl | undefined {
    return this.ngControl?.control as UntypedFormControl;
  }

  validate(): ValidationErrors | null {
    return this.formControl.valid ? null : { formInvalid: true };
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  registerOnChange(fn: any): void {
    this.formControl.valueChanges
      .pipe(
        map((value) => this.mapValue(value)),
        untilDestroyed(this)
      )
      .subscribe(fn);
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  registerOnTouched(fn: any): void {
    this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(fn);
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  writeValue(value: any): void {
    this.formControl.patchValue(value);
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  mapValue(value: any): any {
    return value;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }
}
