
@if (theme === 'tngh') {
  <tngh-reset-password
    (resetPassword)="onSubmit($event)"
  ></tngh-reset-password>
} @else {
  <mdef-reset-password
    (resetPassword)="onSubmit($event)"
  ></mdef-reset-password>
}
