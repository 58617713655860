<div class="login-screen">
  <tngh-logo />
  <form
    [formGroup]="loginForm"
    (ngSubmit)="submit()"
    class="login-form">
    <tngh-form-grid>
      <tngh-text-input
        class="span-6"
        formControlName="emailAddress"
        autocapitalize="none"
        label="Email address"
        i18n-label="Label emailaddress@@emailaddress"
        [showFormErrors]="showFormErrors"></tngh-text-input>
      <tngh-text-input
        class="span-6"
        autocapitalize="none"
        type="password"
        formControlName="password"
        label="Password"
        i18n-label="Label password@@password"
        [showFormErrors]="showFormErrors"></tngh-text-input>
      <tngh-alert
        *ngIf="loginError"
        class="span-6"
        type="danger"
        title="Unable to login"
        i18n-title="login error alert title@@loginFormAlertTitle"
        alertText="Make sure your email address and password are correct."
        i18n-alertText="login error alert text@@loginFormAlertText"></tngh-alert>
    </tngh-form-grid>
    <div class="login-screen__cta">
      <tngh-button
        i18n-label="login button|The button that will actually login the user@@loginButton"
        label="Login"
        [fullwidth]="true"
        type="submit"
        class="login-form__btn" />
      <tngh-button
        i18n-label="password reset request button|The button that will redirect the user to request a password reset@@RequestPasswordResetButton"
        label="Forgot password?"
        variant="link"
        class="login-form__link"
        (clickEvent)="navigateToPasswordReset()" />
    </div>
  </form>
  <svg
    class="bg-pattern"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 834 115">
    <path
      fill="var(--login-gradient-middle, var(--color-primary-100))"
      d="M372.694 73.566C275.521 119.704 85.485 138.105 0 68.6V115h834V64.539S750.079 0 649.478 0C548.876 0 518.122 4.513 372.694 73.566Z" />
  </svg>
</div>
