import {ErrorHandler, Inject, Injectable, Injector} from '@angular/core';
import {ApplicationInsightsLoggingService} from '../logging.service';
import {SharedModuleConfig} from "../config/shared-module-config";

@Injectable({
  providedIn: 'root'
})
export class MdefErrorHandlerService implements ErrorHandler {

  private production;
  constructor(@Inject(Injector) private injector: Injector, private loggingService: ApplicationInsightsLoggingService, private sharedModuleConfig: SharedModuleConfig) {
    this.production = this.sharedModuleConfig.production;
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  handleError(error: any): void {
    // if (!this.production) {
    console.error(error);
    // }
    this.loggingService.logException(error);
  }
}
