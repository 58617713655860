import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {
  ButtonComponent,
  confirmPasswordValidator,
  FormCardComponent,
  FormGridComponent,
  LogoComponent, MdefResetPasswordComponent, ResetPasswordComponent,
  TextInputComponent,
} from 'component-library';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from "../../config/authentication-service";
import {AuthenticationConfig} from "../../config/authentication-config";
import {PasswordReset} from "../model/password-reset";

@Component({
  selector: 'tngh-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['../login/login.component.scss', '../../../../component-library/src/lib/tablet/form-card/form-card-form-wrapper.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ButtonComponent, FormGridComponent, FormCardComponent, LogoComponent, TextInputComponent, MdefResetPasswordComponent, ResetPasswordComponent]
})
export class ResetPasswordPageComponent {
  formGroup: FormGroup;
  resetCode: string | undefined;
  theme: 'tngh' | 'mdef';

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    fb: FormBuilder,
    config: AuthenticationConfig) {
    this.theme = config.theme ?? 'tngh';

    this.route.queryParams.subscribe((params) => {
      this.resetCode = params['resetCode'];
    });

    this.formGroup = fb.group(
      {
        newPassword: [undefined, [Validators.required, Validators.minLength(12)]],
        confirmPassword: [undefined, [Validators.required, Validators.minLength(12)]]
      },
      {validators: confirmPasswordValidator}
    );
  }

  onSubmit(passwordResetDTO: Partial<PasswordReset>) {
    if (passwordResetDTO && this.resetCode) {
      const resetPassword = {
        resetCode: this.resetCode,
        confirmPassword: passwordResetDTO.confirmPassword!,
        newPassword: passwordResetDTO.newPassword!
      };
      this.authenticationService.resetPassword(resetPassword).subscribe();
    }
  }
}
