import {EnvironmentInterface} from './environment-interface';

export const environment: EnvironmentInterface = {
  mdefService: 'https://be-mdef-tst.nextgenhosting.eu/api',
  theme: 'mdef',
  skipQuestions: false,
  appInsights: {
    instrumentationKey: '1399ed6e-82f9-46ca-9508-905ee609ba20'
  },
  production: false,
  keycloakUrl: 'https://iam-mdef-tst.nextgenhosting.eu/',
  clientId: 'webapp-client',
  clientSecret: 'IqG1McwRG6zBAza6rRee0m73ckriRNGJ',
  realm: 'MDEF',
};
