import { ToasterVariant } from './toaster-variant';
import { AvailableIcons } from './available-icons';

export interface ToasterConfig {
  title: string;
  description: string;
  variant: ToasterVariant;
  icon?: AvailableIcons;
}

export class ToasterConfigBuilder {
  config: ToasterConfig = {
    title: '',
    description: '',
    variant: 'info'
  };

  title(title: string): this {
    this.config.title = title;
    return this;
  }

  description(description: string): this {
    this.config.description = description;
    return this;
  }

  variant(variant: ToasterVariant): this {
    this.config.variant = variant;
    return this;
  }

  icon(icon: AvailableIcons): this {
    this.config.icon = icon;
    return this;
  }

  build(): ToasterConfig {
    return this.config;
  }
}
