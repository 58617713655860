import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {ButtonComponent} from "../../base/button/button.component";
import {FormCardComponent} from "../../tablet/form-card/form-card.component";
import {FormGridComponent} from "../../layouts";
import {TextInputComponent} from "../../form-inputs";
import {LogoComponent} from "../../base/logo/logo.component";

@Component({
  selector: 'lib-request-password-reset-form',
  standalone: true,
  imports: [
    ButtonComponent,
    FormCardComponent,
    FormGridComponent,
    LogoComponent,
    ReactiveFormsModule,
    RouterLink,
    TextInputComponent
  ],
  templateUrl: './request-password-reset-form.component.html',
  styleUrls: ['../login-form/login-form.component.scss', '../../tablet/form-card/form-card-form-wrapper.scss'],
})
export class RequestPasswordResetFormComponent {
  @Output() requestReset = new EventEmitter<string>();
  @Output() navigateBack = new EventEmitter<void>();

  formGroup: FormGroup;

  constructor(fb: FormBuilder) {
    this.formGroup = fb.group({
      emailAddress: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const emailAddress = this.formGroup.value.emailAddress;
      this.requestReset.emit(emailAddress);
    }
  }

  onClickBackButton() {
    this.navigateBack.emit();
  }
}
