import {Observable} from "rxjs";
import {PasswordChange} from "../lib/model/password-change";
import {PasswordReset} from "../lib/model/password-reset";

export abstract class AuthenticationService {
  abstract changePasswordRequest: (passwordChange: PasswordChange) => Observable<void>;
  abstract resetPasswordRequest: (emailAddress: string) => Observable<void>;
  abstract resetPassword: (passwordReset: PasswordReset) => Observable<void>;
  abstract setPassword: (passwordReset: PasswordReset) => Observable<void>;
}
