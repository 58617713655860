export class RoutingNames {
  static root = '';
  static dashboard = 'dashboard';
  static create = 'create';
  static edit = 'edit';
  static company = 'company';
  static user = 'user';
  static authentication = 'authentication';
  static permissionDenied = 'permissionDenied';
  static order = 'order';
  static orders = 'orders';
  static productManagement = 'product-management';
  static login = 'login';
  static cateringCompanies = 'catering-companies';
  static cateringCompaniesDetail = ':id';
  static cateringCompaniesDetailNew = 'new';
  static users = 'users';
  static usersDetail = ':id';
  static usersNew = 'new';
  static table = ':tableId';
  static tableBase = 'table';
  static tables = 'tables';
  static menu = 'menu';
  static menus = 'menus';
  static menuCategoriesOverview = ':menuId';
  static menuCategoryOverview = ':categoryId';
  static menuSubCategoryOverview = ':subCategoryId';
  static menuProductDetail = ':productId';
  static menuManagement = 'menu-management';
  static printerManagement = 'printer-management';
  static products = 'products';
  static category = 'category';
  static subcategory = 'subcategory';
  static cateringCompanySettings = 'catering-company-settings';
  static cateringCompanyTableManagement = 'table-management';
  static aboutCompany = 'about';
  static pincode = 'pincode';
  static checkOut = 'check-out';
  static receipt = 'receipt';
  static details = 'details';
  static payment = 'payment';
  static invoice = 'invoice';
  static pos = 'pos';
  static changePassword = 'change-password';
  static changeDetails = 'details';
  static resetPasswordRequest = 'password-reset';
  static passwordReset = 'reset-password';
  static passwordSet = 'set-password';
  static zReport = 'z-report';
  static standardProducts = 'standard-products';
  static standardProductsOverview = 'overview';
  static standardProductsEdit = 'standard-products-edit';
  static standardProductsCreate = 'standard-products-create';
  static copyStandardProducts = 'standard-products-copy';
  static cateringCompanyFloorplanManagement = 'floorplan-management';
  static split = 'split';
  static invoiceId = ':invoiceId';
  static zreportId = ":id";
  static posSettings = 'pos-settings';
}
