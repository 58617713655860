<tngh-dialog [variant]="variant" [title]="title">
  <ng-container dialog-body>{{ message }}</ng-container>
  <ng-container dialog-footer>
    <tngh-button
      [label]="cancelLabel"
      variant="link"
      type="button"
      (clickEvent)="closeDialog('CANCEL')">
    </tngh-button>
    <tngh-button
      [label]="confirmLabel"
      type="button"
      size="sm-condensed"
      (clickEvent)="closeDialog('CONFIRM')">
    </tngh-button>
  </ng-container>
</tngh-dialog>
