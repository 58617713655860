<div class="login-screen">
  <form
    [formGroup]="signupForm"
    (ngSubmit)="submit()"
    class="login-form">
    <fieldset>
      <tngh-form-section
        title="Signup"
        i18n-title="signup@@signupTitle"
      >
        <tngh-form-grid>
          <tngh-text-input
            class="span-6"
            formControlName="email"
            autocapitalize="none"
            label="Email address"
            i18n-label="Label emailaddress@@emailaddress"
            [showFormErrors]="showFormErrors"></tngh-text-input>
          <tngh-text-input
            class="span-6"
            formControlName="name"
            autocapitalize="words"
            label="Name"
            i18n-label="Label name@@name"
            [showFormErrors]="showFormErrors"></tngh-text-input>
          <tngh-text-input
            class="span-6"
            formControlName="surname"
            autocapitalize="words"
            label="Surname"
            i18n-label="Label surname@@surname"
            [showFormErrors]="showFormErrors"></tngh-text-input>
        </tngh-form-grid>
      </tngh-form-section>
    </fieldset>
    <div class="login-screen__cta">
      <tngh-button
        i18n-label="signup button|signup confirmation, sends the form@@signupButton"
        label="Sign up"
        [fullwidth]="true"
        type="submit"
        class="login-form__btn"/>
    </div>
  </form>
</div>
