<!-- TODO: This should be either a button or an anchor depending on where the button is placed (link or save) -->
@if(useLink) {
<a
  #button
  (debounceClick)="onClickEvent()"
  [debounceTime]="debounceTime"
  tnghDebounceClick
  class="btn"
  [ngClass]="['btn--' + variant]"
>
  <tngh-svg-icon
    *ngIf="iconName"
    [name]="iconName"
    [variant]="variant"
    [size]="iconSize"
  >
  </tngh-svg-icon>
  <span [class.sr-only]="variant === 'icon-only'"> {{ label }} </span>
</a>
} @else {
<button
  #button
  (debounceClick)="onClickEvent()"
  [debounceTime]="debounceTime"
  tnghDebounceClick
  class="btn"
  [ngClass]="['btn--' + variant]"
>
  <tngh-svg-icon
    *ngIf="iconName"
    [name]="iconName"
    [variant]="variant"
    [size]="iconSize"
  >
  </tngh-svg-icon>
  <span [class.sr-only]="variant === 'icon-only'"> {{ label }} </span>
</button>
}
