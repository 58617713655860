import { Component, Input, Optional, Self } from '@angular/core';
import { CvaBaseComponent } from 'shared';
import { AbstractControl, FormBuilder, FormGroup, NgControl, ReactiveFormsModule, ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormGridComponent, FormSectionComponent } from '../../layouts';
// prevent circular dependencies
import { TextInputComponent } from '../../form-inputs/text-input/text-input.component';

@Component({
  selector: 'tngh-change-password',
  templateUrl: './change-password.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormSectionComponent, FormGridComponent, TextInputComponent]
})
export class ChangePasswordComponent extends CvaBaseComponent {
  @Input() showFormErrors = false;

  @Input()
  set isInvalidPassword(bool: boolean) {
    this.formControl.get('oldPassword')?.setErrors(
      bool
        ? {
            invalidPassword: {
              message: $localize`:invalid password entered|Change password page@@ChangePasswordPageInvalidPasswordGiven:Invalid password entered`
            }
          }
        : null
    );
  }

  override formControl: FormGroup;

  constructor(@Optional() @Self() override ngControl: NgControl, private fb: FormBuilder) {
    super(ngControl);
    this.selfValidate = true;
    this.formControl = this.fb.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.minLength(12), Validators.required]],
        confirmPassword: ['', [Validators.minLength(12), Validators.required]]
      },
      { validators: [confirmPasswordValidator] }
    );
  }
}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  control.value.newPassword !== control.value.confirmPassword
    ? control.get('confirmPassword')?.setErrors({
        PasswordNoMatch: {
          message: $localize`:none matching passwords@@ChangePasswordPageNoneMatchingPasswordsGiven:New and Confirm passwords do not match.`
        }
      })
    : null;
  return control.value.newPassword === control.value.confirmPassword ? null : { PasswordNoMatch: true };
};
