import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {ButtonComponent, ChangePasswordComponent} from 'component-library';
import {AuthenticationService} from "../../config/authentication-service";

@Component({
  selector: 'tngh-change-password-page',
  templateUrl: './change-password-page.component.html',
  styleUrls: ['../../../../component-library/src/lib/form-inputs/form-styles/form-styles.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ChangePasswordComponent, ButtonComponent]
})
export class ChangePasswordPageComponent {
  formGroup: FormGroup;
  passwordReset: FormControl = new FormControl();
  showFormErrors = false;
  isInvalidPassword = false;

  constructor(
    private providedAuthenticationService: AuthenticationService,
    fb: FormBuilder
  ) {
    this.formGroup = fb.group({});
  }

  onSubmit() {
    if (this.passwordReset.valid) {
      this.providedAuthenticationService.changePasswordRequest(this.passwordReset.value).subscribe({
        error: (error) => {
          if (error.error.message === 'Invalid password given') {
            this.isInvalidPassword = true;
            this.showFormErrors = true;
          }
        }
      });
    } else {
      this.showFormErrors = true;
    }
  }
}
