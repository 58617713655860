<!-- eslint-disable  @angular-eslint/template/valid-aria -->
<p aria-role="alert" class="form-validation">
  <ng-container i18n="@@textInputRequired" *ngIf="errors['required']"
  >{{ label | titlecase }} is required
  </ng-container>
  <ng-container i18n="@@textInputRequiredTrue" *ngIf="errors['requiredTrue']">
    {{ label | titlecase }} needs to be accepted
  </ng-container>
  <ng-container i18n="@@textInputMin" *ngIf="errors['min']">
    {{ label | titlecase }} minimum is not met
  </ng-container>
  <ng-container i18n="@@textInputMinLength" *ngIf="errors['minlength']">
    {{ label | titlecase }} minimum length must be {{ errors['minlength'].requiredLength }} characters
  </ng-container>
  <ng-container i18n="@@textInputMax" *ngIf="errors['max']">
    {{ label | titlecase }} maximum has been exceeded
  </ng-container>
  <ng-container i18n="@@textInputMaxLength" *ngIf="errors['maxlength']">
    {{ label | titlecase }} maximum length must be {{ errors['maxlength'].requiredLength }} characters
  </ng-container>
  <ng-container i18n="@@textInputEmail" *ngIf="errors['email']">
    {{ label | titlecase }} is invalid
  </ng-container>
  <ng-container i18n="@@textInputPattern" *ngIf="errors['pattern']">
    {{ label | titlecase }} input is invalid
  </ng-container>
  <ng-container *ngFor="let item of errors | keyvalue">
    <ng-container *ngIf="isCustomValidationError(item)">
      <!-- custom message defined in your custom validator -->
      {{ item.value.message }}
    </ng-container>
  </ng-container>
</p>
