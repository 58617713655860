@if (theme === 'tngh') {
<lib-login-form
  [loginError]="loginError"
  (loginRequest)="doLogin($event)"
  (passwordResetRequest)="navigateToPasswordReset()"
></lib-login-form>
} @else {
<mdef-login-form
  [loginError]="loginError"
  (loginRequest)="doLogin($event)"
  (passwordResetRequest)="navigateToPasswordReset()"
></mdef-login-form>
}
