<fieldset [formGroup]="formControl">
  <legend class="sr-only">Change password select</legend>
  <tngh-form-section
    title="Change password"
    i18n-title="@@changePasswordForm"
    description="You can change your current password by entering your current password and choosing a new password.">
    <tngh-form-grid>
      <tngh-text-input
        class="span-6"
        formControlName="oldPassword"
        helpText="Enter your current password"
        i18n-helpText="Enter your current password@@currentPasswordField"
        label="Current password"
        type="password"
        i18n-label="@@oldPasswordField"
        [showFormErrors]="showFormErrors"></tngh-text-input>
      <tngh-text-input
        class="span-6"
        helpText="Minimum of 12 characters"
        i18n-helpText="Minimum of 12 characters@@newPasswordFieldMinimumOf12Characters"
        formControlName="newPassword"
        label="New password"
        type="password"
        i18n-label="@@newPasswordField"
        [showFormErrors]="showFormErrors"></tngh-text-input>
      <tngh-text-input
        class="span-6"
        formControlName="confirmPassword"
        label="Repeat new password"
        type="password"
        i18n-label="@@confirmPasswordField"
        [showFormErrors]="showFormErrors"></tngh-text-input>
    </tngh-form-grid>
  </tngh-form-section>
</fieldset>
