<div
  class="alert"
  role="alert"
  [ngClass]="['alert--' + type]">
  <tngh-svg-icon
    size="md"
    [name]="namePerType[type]"
    class="alert__icon"></tngh-svg-icon>

  <p class="alert__title">{{ title }}</p>
  <p class="alert__text">{{ alertText }}</p>
</div>
