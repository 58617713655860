import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../../base';
import { SvgIconComponent } from '../../base';
import {Async, AvailableIcons, DialogVariants } from 'shared';

@Component({
  selector: 'tngh-dialog',
  standalone: true,
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  imports: [CommonModule, ButtonComponent, SvgIconComponent]
})
export class DialogComponent {
  private _variant: DialogVariants = 'primary';
  @Input() icon?: AvailableIcons;
  @Input() title: Async<string> = '';

  @Input()
  set variant(value: DialogVariants) {
    if (!this.icon && value === 'warning') {
      this.icon = 'exclamation-triangle';
    }

    if (!this.icon && value === 'danger') {
      this.icon = 'exclamation-sign';
    }

    this._variant = value;
  }

  get variant(): DialogVariants {
    return this._variant;
  }
}
