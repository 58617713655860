import { Pipe, PipeTransform } from '@angular/core';

export const generateId = (value: string) => {
  return value?.replace(/[^a-zA-Z]+/g, '') + Math.floor(Math.random() * 10255);
};

@Pipe({
  name: 'id',
  standalone: true
})
export class IdPipe implements PipeTransform {
  transform = generateId;
}
