import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgIf} from "@angular/common";
import {User} from "shared";
import {AlertComponent} from "../../base/alert/alert.component";
import {ButtonComponent} from "../../base/button/button.component";
import {FormGridComponent} from "../../layouts";
import {LogoComponent} from "../../base/logo/logo.component";
import {TextInputComponent} from "../../form-inputs";

@Component({
  selector: 'lib-login-form',
  standalone: true,
  imports: [
    AlertComponent,
    ButtonComponent,
    FormGridComponent,
    FormsModule,
    LogoComponent,
    NgIf,
    ReactiveFormsModule,
    TextInputComponent
  ],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss'
})
export class LoginFormComponent {
  @Input() loginError = false;
  @Output() loginRequest = new EventEmitter<User>();
  @Output() passwordResetRequest = new EventEmitter<void>();
  loginForm: FormGroup;
  showFormErrors = false;

  constructor(fb: FormBuilder) {
    this.loginForm = fb.group({
      emailAddress: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  submit() {
    if (this.loginForm.invalid) {
      this.showFormErrors = true;
    } else {
      this.loginRequest.emit(this.loginForm.value);
    }
  }

  navigateToPasswordReset() {
    this.passwordResetRequest.emit();
  }
}
