<div
  class="dialog-content-wrapper"
  [ngClass]="'dialog-content-wrapper--' + variant">
  <div class="dialog-content">
    <div class="dialog-content__icon" *ngIf="icon">
      <tngh-svg-icon [name]="icon"></tngh-svg-icon>
    </div>
    <div class="dialog-body">
      <h2 class="dialog-body__title">{{ title }}</h2>
      <div class="dialog-body__content">
        <ng-content select="[dialog-body]"></ng-content>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <ng-content select="[dialog-footer]"></ng-content>
  </div>
</div>
