import { Component, Input } from '@angular/core';
import { CommonModule, KeyValue } from '@angular/common';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'tngh-validation-error-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './validation-error-message.component.html',
  styleUrls: ['../../form-inputs/form-styles/form-control-styles.scss']
})
export class ValidationErrorMessageComponent {
  @Input({ required: true }) singleFormControl!: { label: string; errors: ValidationErrors | null | undefined };
  protected readonly Object = Object;

  get label() {
    return this.singleFormControl?.label || '';
  }

  get errors() {
    return this.singleFormControl?.errors || {};
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  isCustomValidationError(error: KeyValue<string, any>): boolean {
    if (typeof error.value == 'boolean') {
      return false;
    }
    return 'message' in error.value;
  }
}
