import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { AvailableIcons } from 'shared';

@Component({
  selector: 'tngh-alert',
  standalone: true,
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  imports: [CommonModule, SvgIconComponent]
})
export class AlertComponent {
  @Input() title = '';
  @Input() icon?: AvailableIcons;
  @Input() alertText = '';
  @Input() type: 'info' | 'warning' | 'danger' | 'success' = 'info';

  namePerType: { [key in string]: AvailableIcons } = {
    info: 'info-circle',
    warning: 'exclamation-triangle',
    danger: 'exclamation-sign',
    success: 'checkmark-circle'
  };
}
