<div class="login-screen">
  <a routerLink="/">
    <tngh-logo />
  </a>

  <tngh-form-card>
    <tngh-button
      class="login-back-button"
      variant="back"
      label="Back"
      iconPosition="start"
      (click)="onClickBackButton()"
      iconName="chevron-left" />
    <form
      [formGroup]="formGroup"
      class="form-card-form-wrapper">
      <fieldset>
        <legend
          class="form-card-form-wrapper__legend"
          i18n>
          Request password reset
        </legend>
        <tngh-form-grid>
          <tngh-text-input
            class="span-6"
            formControlName="emailAddress"
            helpText="Enter the email address you use to login"
            i18n-helpText="Enter your email address that you use to login@@ResetPasswordEmailaddressFieldHelptext"
            label="Email address"
            i18n-label="@@emailaddressField" />
        </tngh-form-grid>
      </fieldset>
      <div class="login-screen__cta">
        <tngh-button
          [debounceTime]="500"
          (clickEvent)="onSubmit()"
          type="submit"
          i18n-label="@@resetRequestSubmitButton"
          [fullwidth]="true"
          label="Submit" />
      </div>
    </form>
  </tngh-form-card>

  <svg
    class="bg-pattern"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 834 115">
    <path
      fill="var(--login-gradient-middle, var(--color-primary-100))"
      d="M372.694 73.566C275.521 119.704 85.485 138.105 0 68.6V115h834V64.539S750.079 0 649.478 0C548.876 0 518.122 4.513 372.694 73.566Z" />
  </svg>
</div>
