import {Injector, ModuleWithProviders, NgModule, Type} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {authenticationRoutes} from './authentication.routes';
import {ReactiveFormsModule} from '@angular/forms';
import {PermissionDeniedComponent} from './permission-denied/permission-denied.component';
import {LoginComponent} from './login/login.component';
import {
  AlertComponent,
  ButtonComponent,
  FormCardComponent,
  FormGridComponent,
  LogoComponent,
  SvgIconComponent,
  TextInputComponent,
  LoginFormComponent,
  MdefLoginFormComponent
} from 'component-library';
import {AuthenticationConfig} from "../config/authentication-config";
import {AuthenticationService} from "../config/authentication-service";

@NgModule({
  providers: [],
  declarations: [PermissionDeniedComponent, LoginComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(authenticationRoutes),
    ReactiveFormsModule,
    SvgIconComponent,
    ButtonComponent,
    FormGridComponent,
    TextInputComponent,
    FormCardComponent,
    LogoComponent,
    AlertComponent,
    LoginFormComponent,
    MdefLoginFormComponent
  ]
})
export class AuthenticationModule {
  static forRoot(config: AuthenticationConfig, authenticationService: Type<AuthenticationService>): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [{ provide: AuthenticationConfig, useValue: config }, { provide: AuthenticationService, useFactory: (inj: Injector) => inj.get(authenticationService), deps: [Injector] }],
    };
  }

}
