<div class="login-screen">
    <img class="login-screen__logo" src="./assets/images/logo-mdef.svg" alt="Logo Ministerie van Defensie">
  <form
    [formGroup]="loginForm"
    (ngSubmit)="submit()"
    class="login-form">
    <tngh-form-grid>
      <tngh-text-input
        class="span-6"
        formControlName="emailAddress"
        autocapitalize="none"
        label="Email address"
        i18n-label="Label emailaddress@@emailaddress"
        [showFormErrors]="showFormErrors"></tngh-text-input>
      <tngh-text-input
        class="span-6"
        autocapitalize="none"
        type="password"
        formControlName="password"
        label="Password"
        i18n-label="Label password@@password"
        [showFormErrors]="showFormErrors"></tngh-text-input>
      <tngh-alert
        *ngIf="loginError"
        class="span-6"
        type="danger"
        title="Het is niet gelukt om in te loggen."
        i18n-title="login error alert title@@loginFormAlertTitle"
        alertText="Controleer je e-mailadres en wachtwoord."
        i18n-alertText="login error alert text@@loginFormAlertText"></tngh-alert>
    </tngh-form-grid>
    <div class="login-screen__cta">
      <mdef-button
        i18n-label="login button|The button that will actually login the user@@loginButton"
        label="Login"
        [fullwidth]="true"
        type="submit"
        class="login-form__btn" />

      <mdef-button
        i18n-label="password reset request button|The button that will redirect the user to request a password reset@@RequestPasswordResetButton"
        label="Wachtwoord vergeten?"
        variant="link"
        class="login-form__link"
        (clickEvent)="navigateToPasswordReset()" />
    </div>
  </form>
  <div class="login-screen__visual">
    <svg class="login-screen__title" xmlns="http://www.w3.org/2000/svg" width="537" height="163" fill="none" viewBox="0 0 537 163">
      <title>Navy Ethic Game</title>
      <path fill="#fff" d="M361.673 108v11h28.7v15.5h-28.7v10.6h31.2V162h-49.3V91.2h49.3V108h-31.2ZM266.717 162V91.2h17.9l14.9 34.6 15-34.6h17.9V162h-18l.1-20.4 1.1-14.3-.6-.1-9.3 24.3h-12.3l-9.5-24.3-.5.1 1.2 14.3V162h-17.9ZM236.673 91.2l25.3 70.8h-19.2l-4.6-14.2h-18.9l-4.4 14.2h-19.2l24.7-70.8h16.3Zm-13.4 40.7h10.6l-4.9-20.7h-.5l-5.2 20.7ZM191.738 105.3l-15 6.9c-3.9-9.5-19.7-7.3-19.7 2v24.3c0 11.1 20.7 11 20.7 0v-2.6h-11.3v-15h28.5v17.6c0 16.3-13.6 24.5-27.8 24.5-14 0-28-8.2-28-24.5v-24.3c0-16 14-24 28-24 9.2 0 19.7 3.8 24.6 15.1ZM521.03 49.9l15.6 7.2c-5.9 11.5-16.6 15.9-25.5 15.9-13.9 0-27.9-8.1-27.9-24.2V24.4c0-16 14-24.2 28.2-24.2 8.7 0 19.2 3.7 24.9 16.3l-14.6 5.9c-4.8-8.8-20.6-6.8-20.6 2v24.4c0 8.5 16 10.9 19.9 1.1ZM473.728 1.2V72h-17.9V1.2h17.9ZM406.25 45.3V72h-18V1.2h18V28h20.4V1.2h18V72h-18V45.3h-20.4ZM381.254 18.1h-18.8V72h-18.1V18.1h-18.8v-17h55.7v17ZM289.065 18v11h28.7v15.5h-28.7v10.6h31.2V72h-49.3V1.2h49.3V18h-31.2Z"/>
      <path fill="#FF7F00" d="M226.792 72h-17.9V44.1l-24.7-42.9h20.8l12.8 26 12.8-26h21l-24.8 42.9V72Z"/>
      <path fill="#FF7F00" d="m155.567 35.8 11.2-34.7h19.2l-25.5 70.8h-16.4l-25.5-70.8h19.2l11.1 34.6 3.2 16.8h.3l3.2-16.7ZM102.445 1.2l25.3 70.8h-19.2l-4.6-14.2h-18.9l-4.4 14.2h-19.2l24.7-70.8h16.3Zm-13.4 40.7h10.6l-4.9-20.7h-.5l-5.2 20.7ZM.067 72V1.2h17l17.1 30.2 6 14.2.5-.1c-.1 0-1.9-12.2-2-12.2V1.2h17.8V72h-18.1l-16-27.9-6.2-14.2-.5.1 2.3 13.7V72H.067Z"/>
    </svg>

    <img class="login-screen__image" decoding="async" height="1024" width="1798" src="./assets/images/mdef-login-bg.jpg" alt="Marineschepen op zee" />
  </div>
</div>
