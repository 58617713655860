import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { RoutingNames } from 'shared';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private lastUrl = '/';

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (
          !event.url.includes('authentication/login') &&
          !event.url.includes('permissionDenied') &&
          !event.url.includes('authentication/password-reset')
        )
          this.lastUrl = event.url;
      }
    });
  }

  back(): void {
    if (this.lastUrl.includes('reset-password')) this.router.navigate(['/']);
    this.router.navigateByUrl(this.lastUrl);
  }

  passwordResetRequest(): void {
    this.router.navigate([RoutingNames.authentication, RoutingNames.resetPasswordRequest]);
  }
}

export const factoryNavigationService = () => {
  return () => null;
};
