import { Component } from '@angular/core';
import {RequestPasswordResetFormComponent} from "../../request-password-reset-form.component";
import {AlertComponent, FormGridComponent, MdefButtonComponent, TextInputComponent} from "../../../../../index";
import {NgIf} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'mdef-request-password-reset-form',
  standalone: true,
  imports: [
    AlertComponent,
    FormGridComponent,
    MdefButtonComponent,
    NgIf,
    ReactiveFormsModule,
    TextInputComponent
  ],
  templateUrl: './mdef-request-password-reset-form.component.html',
  styleUrl: '../../../login-form/mdef/mdef-login-form.component.scss'
})
export class MdefRequestPasswordResetFormComponent extends RequestPasswordResetFormComponent {

}
