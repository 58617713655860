import {Injectable} from "@angular/core";
import {RoutingNames, ToasterConfigBuilder, ToasterService} from "shared";
import {Observable, of, tap} from "rxjs";
import {AuthenticationService, PasswordChange, PasswordReset} from "authentication";
import {Router} from "@angular/router";
import { UserControllerService } from "mdef-api";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class MdefAuthenticationService implements AuthenticationService {

  constructor(private userController: UserControllerService, private toasterService: ToasterService, private router: Router) {
  }
  changePasswordRequest(newPassword: PasswordChange): Observable<void> {
    return of()
  }

  resetPasswordRequest(emailAddress: string): Observable<void> {
    return this.userController.resetPasswordRequest(emailAddress).pipe(map(() => {
      this.toasterService.addToaster(
        new ToasterConfigBuilder()
          .title($localize`:passwordResetRequest@@passwordResetRequested:Password reset requested`)
          .description($localize`:passwordResetRequested@@passwordResetRequestMessage:Your password reset request was sent`)
          .variant('success')
          .icon('checkmark-circle')
          .build()
      );
      this.router.navigate([RoutingNames.authentication, RoutingNames.login]);
    }));
  }


  resetPassword(passwordReset: PasswordReset): Observable<void> {
    return this.userController.resetPassword(passwordReset).pipe(tap(console.log),map(() => {
      this.toasterService.addToaster(
        new ToasterConfigBuilder()
          .title($localize`:passwordUpdated@@passwordUpdated:Password updated`)
          .description($localize`:passwordUpdated@@passwordUpdatedMessage:Your password was updated successfully`)
          .variant('success')
          .icon('checkmark-circle')
          .build()
      );

      this.router.navigate([RoutingNames.authentication, RoutingNames.login]);
    }));
  }
  setPassword(passwordSet: PasswordReset): Observable<void> {
    return of()
  }
}
