@if (signUp) {
  <lib-signup-form
    (signup)="doSignup($event)"
  ></lib-signup-form>
  <span i18n="account exists link@@accountExists">Already have an account? Please </span>
  <tngh-button variant="link"
               label="sign in"
               (clickEvent)="signIn()"
               i18n-label="sing in link for existing accounts@@signInExistingAccount"></tngh-button>
} @else {
  <lib-otp-form
    (sendOtpRequest)="doSendOtpRequest($event)"
    (loginRequest)="doLoginRequest($event)"
  ></lib-otp-form>
}
