import { CanDeactivateCheck } from './can-deactivate-check';
import { Dialog } from '@angular/cdk/dialog';
import { map } from 'rxjs';
import {Injectable} from "@angular/core";
import { ConfirmDialogData, ConfirmDialogComponent } from 'component-library'

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard {
  constructor(private dialog: Dialog) {}

  canDeactivate(component: CanDeactivateCheck) {
    if (component.canDeactivate()) {
      return true;
    } else {
      const dialogData: ConfirmDialogData = {
        variant: 'primary',
        title: $localize`:general confirm@@generalConfirm:Are you sure?`,
        message: $localize`:confirm ignore unsaved changes@@confirmIgnoreUnsavedChanges:You have unsaved changes, are you sure you want to leave this page and lose your changes?`,
        cancelLabel: $localize`:confirm discard changes@@confirmDiscardChanges:Discard changes`,
        confirmLabel: $localize`:cancel discard changes@@cancelDiscardChanges:Stay on page`,
        switchButtonOrder: true
      };
      // If we cancel, we discard the changes and we can deactivate.
      return this.dialog
        .open(ConfirmDialogComponent, { data: dialogData })
        .closed.pipe(map((result) => result === 'CANCEL'));
    }
  }
}
