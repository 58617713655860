import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors
} from '@angular/forms';
import { NgIf } from '@angular/common';
import { AlertComponent } from '../../../base/alert/alert.component';
import { MdefButtonComponent } from '../../../base/button/mdef/mdef-button.component';
import { FormGridComponent } from '../../../layouts';
import { TextInputComponent } from '../../../form-inputs';
import { LoginFormComponent } from '../login-form.component';
const DutchRequiredValidator = (name: string) => {
  return (
    control: AbstractControl,
  ): ValidationErrors | null => {
    if (control.value) {
      return null;
    } else {
      return { dutchRequired: {
          message: name + " is vereist"
        }}
    }
  };
}
@Component({
  selector: 'mdef-login-form',
  standalone: true,
  imports: [
    AlertComponent,
    MdefButtonComponent,
    FormGridComponent,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    TextInputComponent,
  ],
  templateUrl: './mdef-login-form.component.html',
  styleUrl: './mdef-login-form.component.scss',
})
export class MdefLoginFormComponent extends LoginFormComponent {
  constructor(fb: FormBuilder) {
    super(fb);
    this.loginForm = fb.group({
      emailAddress: ['', DutchRequiredValidator('E-mailadres')],
      password: ['', DutchRequiredValidator('Wachtwoord')]
    });
  }
}

