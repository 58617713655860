import { CommonModule } from '@angular/common';
import {Component, Input} from '@angular/core';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { DebounceClickDirective } from '../../../directives/debounce-click.directive';
import { ButtonComponent } from '../button.component';

@Component({
  standalone: true,
  selector: 'mdef-button',
  templateUrl: './mdef-button.component.html',
  styleUrls: ['./mdef-button.component.scss'],
  imports: [CommonModule, SvgIconComponent, DebounceClickDirective],
})
export class MdefButtonComponent extends ButtonComponent {
  @Input() useLink = false;
}
