import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToasterConfig } from "./model";
import { RoutingNames } from "./router/src";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  private static max_amount_of_concurrent_toasters = 5;
  private toasters: ToasterConfig[] = [];
  private allowNewToasters = true;

  constructor(private readonly router: Router) {
    router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e) => (e as NavigationEnd).url.includes(RoutingNames.permissionDenied)),
        takeUntilDestroyed()
      )
      .subscribe((disableToastersEvent) => {
        if (disableToastersEvent) {
          this.allowNewToasters = false;
          for (const toaster of this.toasters) {
            this.removeToaster(toaster);
          }
        } else {
          this.allowNewToasters = true;
        }
      });
  }

  getToasters(): ToasterConfig[] {
    return this.toasters;
  }

  addToaster(toasterToAdd: ToasterConfig) {
    if (
      this.toasters.length < ToasterService.max_amount_of_concurrent_toasters &&
      this.toasters[this.toasters.length - 1]?.title !== toasterToAdd.title &&
      this.allowNewToasters
    ) {
      this.toasters.push(toasterToAdd);
    }
  }

  removeToaster(toasterToRemove: ToasterConfig) {
    const index = this.toasters.findIndex((toaster) => toaster === toasterToRemove);
    this.toasters.splice(index, 1);
  }
}
