import {Component, EventEmitter, Output} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormGridComponent, FormSectionComponent } from '../../layouts';
import { TextInputComponent } from '../../form-inputs';
import {ButtonComponent, FormCardComponent, LogoComponent} from "../../../index";

@Component({
  selector: 'tngh-reset-password',
  templateUrl: './reset-password.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormSectionComponent, FormGridComponent, TextInputComponent, ButtonComponent, FormCardComponent, LogoComponent]
})
export class ResetPasswordComponent {
  formControl: FormGroup;
  @Output() resetPassword = new EventEmitter<{ newPassword: string, confirmPassword: string }>();

  constructor(private fb: FormBuilder) {

    this.formControl = this.fb.group({
      newPassword: ['', Validators.minLength(12)],
      confirmPassword: ['', Validators.minLength(12)]
    });
  }

  onSubmit() {
    if (this.formControl.valid) {
      this.resetPassword.emit(this.formControl.value);
    }
  }

  // protected readonly onsubmit = onsubmit;
}
