import { UntypedFormControl } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { CvaBaseComponent } from './cva-base.component';
import { generateId } from '../id.pipe';

@Component({
  template: ''
})
export class SingleFormControlCvaComponent extends CvaBaseComponent {
  override formControl: UntypedFormControl = new UntypedFormControl();

  _label!: string;
  @Input() helpText?: string;
  labelId!: string;
  helpTextId!: string;
  @Input() labelSrOnly = false;

  @Input()
  set label(label: string) {
    this._label = label;
    this.labelId = generateId(label);
    this.helpTextId = generateId(label);
  }

  get label() {
    return this._label;
  }
}
