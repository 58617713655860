import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { Size, Variants, AvailableIcons } from 'shared';
import { DebounceClickDirective } from '../../directives/debounce-click.directive';

@Component({
  standalone: true,
  selector: 'tngh-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [CommonModule, SvgIconComponent, DebounceClickDirective]
})
export class ButtonComponent {
  @Input() label = '';
  @Input() variant: Variants = 'primary';
  @Input() fullwidth = false;
  @Input() size: 'sm' | 'sm-condensed' | 'md' | 'lg' | '2xl' = 'md';
  @Input() state: 'default' | 'success' | 'warning' | 'danger' = 'default';
  @Input() iconName?: AvailableIcons;
  @Input() iconSize: Size = 'lg';
  @Input() iconPosition: 'start' | 'end' = 'start';
  @Input() type: 'submit' | 'button' | 'reset' = 'button';
  @Input() environment: 'admin' | 'tablet-menu' | 'pos' = 'admin';
  @Input() disabled = false;
  @Input() debounceTime: number = 0;

  @ViewChild('button') buttonComponent?: ElementRef;

  @Output() clickEvent = new EventEmitter<void>();

  onClickEvent() {
    this.clickEvent.emit();
  }

  focus() {
    this.buttonComponent?.nativeElement.focus();
  }
}
