@if (theme === 'tngh') {
  <lib-request-password-reset-form
    (requestReset)="onSubmit($event)"
    (navigateBack)="onClickBackButton()"
  ></lib-request-password-reset-form>
} @else {
  <mdef-request-password-reset-form
    (requestReset)="onSubmit($event)"
    (navigateBack)="onClickBackButton()"
  ></mdef-request-password-reset-form>
}
