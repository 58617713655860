<div class="login-screen">
  <tngh-logo />

  <tngh-form-card>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="form-card-form-wrapper">
      <fieldset>
        <legend class="form-card-form-wrapper__legend" i18n>Set your password</legend>
        <tngh-form-grid>
          <tngh-text-input class="span-6" formControlName="newPassword" label="New password" type="password" i18n-label="@@newPasswordField"></tngh-text-input>
          <tngh-text-input
            class="span-6"
            formControlName="confirmPassword"
            label="Confirm new password"
            type="password"
            i18n-label="@@confirmPasswordField"></tngh-text-input>
        </tngh-form-grid>
      </fieldset>

      <div class="login-screen__cta">
        <tngh-button
          type="submit"
          i18n-label="@@setPasswordSubmitButton"
          [fullwidth]="true"
          label="Set password"
          i18n="Set password button@@ResetPasswordPageButton"></tngh-button>
      </div>
    </form>
  </tngh-form-card>

  <svg class="bg-pattern" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 834 115">
    <path
      fill="var(--login-gradient-middle, var(--color-primary-100))"
      d="M372.694 73.566C275.521 119.704 85.485 138.105 0 68.6V115h834V64.539S750.079 0 649.478 0C548.876 0 518.122 4.513 372.694 73.566Z" />
  </svg>
</div>
