import { Component } from '@angular/core';
import { AuthService } from 'shared';

@Component({
  selector: 'tngh-permission-denied',
  templateUrl: './permission-denied.component.html',
  styleUrls: ['./permission-denied.component.scss']
})
export class PermissionDeniedComponent {
  constructor(private authService: AuthService) {}

  logout() {
    this.authService.logout();
  }
}
