import { Component, Inject } from '@angular/core';
import { DialogComponent } from '../dialog.component';
import { ButtonComponent } from '../../../base';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { SvgIconComponent } from '../../../base';

import { CommonModule } from '@angular/common';
import { DialogVariants } from 'shared';

export type ConfirmDialogResult = 'CONFIRM' | 'CANCEL';

export interface ConfirmDialogData {
  variant?: DialogVariants;
  title?: string;
  message: string;
  cancelLabel: string;
  confirmLabel: string;
  switchButtonOrder?: boolean;
}

@Component({
  standalone: true,
  selector: 'tngh-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  imports: [CommonModule, SvgIconComponent, DialogComponent, ButtonComponent]
})
export class ConfirmDialogComponent {
  variant: DialogVariants;
  title: string;
  message: string;
  cancelLabel: string;
  confirmLabel: string;
  switchButtonOrder: boolean;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) data: ConfirmDialogData
  ) {
    this.variant = data.variant ?? 'danger';
    this.title = data.title ?? '';
    this.message = data.message ?? 'Please confirm';
    this.cancelLabel = data.cancelLabel ?? 'Cancel';
    this.confirmLabel = data.confirmLabel ?? 'OK';
    this.switchButtonOrder = data.switchButtonOrder ?? false;
  }

  closeDialog(value: ConfirmDialogResult): void {
    this.dialogRef.close(value);
  }
}
