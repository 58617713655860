import { Directive, HostListener } from '@angular/core';

@Directive()
export abstract class CanDeactivateCheck {

    abstract canDeactivate(): boolean;

    @HostListener('window:beforeunload', ['$event'])
    preventNavigatingAway(evnt: BeforeUnloadEvent) {
        if (!this.canDeactivate()) {
            evnt.returnValue = '';
        }
    }

}