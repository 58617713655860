<tngh-form-field
  [label]="label"
  [helpText]="helpText"
  [helpTextId]="helpTextId"
  [labelId]="labelId"
  [labelSrOnly]="labelSrOnly">
  <!-- numbers have to be set explicitly, see https://github.com/angular/angular/issues/13243 -->
  <div class="input-wrapper">
    <input
      *ngIf="type === 'number'; else otherType"
      [id]="labelId"
      [class.form-control--invalid]="
        errors && (formControl.dirty || showFormErrors)
      "
      class="form-control"
      type="text"
      inputmode="numeric"
      [name]="label"
      [pattern]="pattern"
      [maxlength]="maxlength"
      [placeholder]="placeholder"
      [autocomplete]="autocomplete ? 'on' : 'off'"
      (keyup)="replaceComma($event)"
      (keydown.enter)="onEnter($event)"
      (blur)="onBlur($event)"
      (keydown.esc)="onBlur($event)"
      [formControl]="formControl"
      [attr.aria-labelledby]="helpTextId" />
    <ng-template #otherType>
      <tngh-svg-icon
        *ngIf="type === 'search'"
        name="magnifier"
        class="input-wrapper__icon"></tngh-svg-icon>
      <input
        #input
        [id]="labelId"
        [class.form-control--invalid]="
          errors && (formControl.dirty || showFormErrors)
        "
        class="form-control"
        [placeholder]="placeholder"
        [autocapitalize]="autocapitalize"
        [maxlength]="maxlength"
        [type]="type"
        [name]="label"
        [max]="max"
        [autocomplete]="autocomplete ? 'on' : 'off'"
        (keydown.enter)="onEnter($event)"
        (keydown.esc)="onBlur($event)"
        (blur)="onBlur($event)"
        [formControl]="formControl"
        [attr.aria-labelledby]="helpTextId" />
    </ng-template>
  </div>
  <ng-container *ngIf="errors && (formControl.dirty || showFormErrors)">
    <tngh-validation-error-message
      [singleFormControl]="this"></tngh-validation-error-message>
  </ng-container>
</tngh-form-field>
