import { Component } from '@angular/core';
import {  ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormGridComponent, FormSectionComponent } from '../../../layouts';
import { TextInputComponent } from '../../../form-inputs';
import {ButtonComponent} from "../../../base/button/button.component";
import {MdefButtonComponent} from "../../../base/button/mdef/mdef-button.component";
import {ResetPasswordComponent} from "../reset-password.component";

@Component({
  selector: 'mdef-reset-password',
  templateUrl: './mdef-reset-password.component.html',
  styleUrl: '../../login-form/mdef/mdef-login-form.component.scss',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormSectionComponent, FormGridComponent, TextInputComponent, MdefButtonComponent, ButtonComponent]
})
export class MdefResetPasswordComponent extends ResetPasswordComponent {

}
