<button
  #button
  (debounceClick)="onClickEvent()"
  [debounceTime]="debounceTime"
  tnghDebounceClick
  class="btn"
  [ngClass]="['btn--' + variant, 'btn--' + size, 'btn--state-' + state, 'theme--' + environment, 'icon--' + iconPosition]"
  [class.btn--fullwidth]="fullwidth"
  [type]="type"
  [disabled]="disabled">
  <tngh-svg-icon
    *ngIf="iconName"
    [name]="iconName"
    [variant]="variant"
    [size]="iconSize">
  </tngh-svg-icon>
  <span [class.sr-only]="variant === 'icon-only'"> {{ label }} </span>
</button>
