import { Routes } from '@angular/router';
import {QuizPageComponent} from './pages/quiz-page/quiz-page.component';
import {SignupPageComponent} from './pages/signup-page/signup-page.component';
import {AuthGuard} from 'authentication';
import {RoutingNames} from 'shared';

export const routes: Routes = [
  {
    path: RoutingNames.authentication,
    loadChildren: () => import('authentication').then((m) => m.AuthenticationModule)
  },{
  path: 'quiz',
  canActivate: [AuthGuard],
  component: QuizPageComponent
}, {
  path: 'signup',
  canActivate: [AuthGuard],
  component: SignupPageComponent
}, {
  path: '',
  pathMatch: 'full',
  redirectTo: 'signup'
}];
